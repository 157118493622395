.header {
    width: 100%;
    z-index: 2;
    margin-bottom: 2rem;
    padding-block: 1rem;

    @media (--screen-lg-xxl ) {
        margin-bottom: 4rem;
    }

    .container {
        display: flex;
        align-items: center;
        padding: 0 var(--side-padding);
        width: 100%;

        @media (--screen-xs-sm) {
            height: 7.5rem;
        }

        @media (--screen-xxl) {
            padding: 0 var(--side-padding);
        }
    }

    .logo {
        flex: 0 0 22rem;

        @media (--screen-md-xl) {
            flex: 0 0 34rem;
        }

        @media (--screen-xxl) {
            flex: 0 0 49.4rem;
        }
    }

    &.-home {
        z-index: 2;
    }

    &.-menu {
        z-index: 5;

        @media (--screen-xs-xl) {
            background-color: var(--color-white);
        }
    }

    .site-app & {
        display: none;
    }
}

.header__nav {
    display: none;

    @media (--screen-xxl) {
        background-color: transparent;
        position: relative;
        top: auto;
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-end;
        height: 5.5rem;
        padding: 0;
    }

    .header.-menu & {
        @media (--screen-xs-xl) {
            display: block;
            width: 100%;
            position: absolute;
            top: 7rem;
            left: 0;
            height: 100%;
            min-height: 100vh;
            background-color: var(--color-white);
            z-index: 5;
            padding-top: 5rem;
            text-align: center;
        }
    }

    &.-no-banner {
        nav, .dropdown {
            height: 5.5rem;
            //border-bottom: .1rem solid var(--color-blue);

            .header.-menu & {
                height: auto;
                border: 0;
            }
        }
    }
}

.header__lang {
    margin: 5rem 0 2.5rem;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
    row-gap: 1rem;
    list-style: none;

    li {
        display: inline-block;

        &::after {
            content: '|';
            padding-left: 1rem;
            display: inline-block;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }

    a {
        text-decoration: none;
        transition: all .3s ease;
        font-weight: 700;

        &:hover, &:focus {
            color: var(--color-orange);
        }

        &.-active {
            text-decoration: underline;
            color: var(--color-purple);
        }
    }

    .dropdown.-open & {
        @media (--screen-xxl) {
            margin: 0;
            flex-wrap: wrap;
            justify-content: flex-start;

            li {
                &.-hidden {
                    display: none;
                }

                &::after {
                    display: none;
                }
            }
        }
    }
}
