figure {
    margin: 0 0 6rem;
    padding: 0;
    max-width: 80rem;

    img {
        width: 100%;
        margin: 0 auto;
    }

    figcaption {
        margin-top: 1rem;
        font-size: 1.3rem;
        line-height: 1.3;
        color: var(--color-purple);
    }

    &.small, &.medium {
        margin: 0 0 3rem;

        img {
            max-width: 68rem;
        }
    }

    &.small {
        img {
            max-width: 31rem;
        }
    }

    &.center {
        figcaption {
            text-align: center;
        }

        text-align: center;
    }

    &.right {
        text-align: right;
    }

    .lineup & {
        margin-bottom: 0;
    }
}
