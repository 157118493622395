.ing-header {
    padding-top: 1rem;
    max-width: 168rem;
    margin: 0 auto 2rem auto;

    @media (--screen-md-xxl) {
        padding-top: 2rem;
        margin: 0 auto 4rem auto;
    }

    img {
        max-width: 100%;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @media (--screen-md-xxl) {
            flex-direction: row;
        }

        div {
            display: flex;
            flex-direction: column;
            width: 100%;
            box-sizing: border-box;

            @media (--screen-md-xxl) {
                width: 50%;
                align-items: center;
                justify-content: center;
            }

            &:first-child {
                @media (--screen-md-xxl) {
                    justify-content: flex-start;
                    padding-right: 4rem;
                }
            }

            &:last-child {
                @media (--screen-md-xxl) {
                    padding-left: 4rem;
                }
            }
        }
    }
}

.ing-header__logo {
    @media (--screen-md-xxl) {
        display: none;
    }

    &.-desktop {
        display: none;

        @media (--screen-md-xxl) {
            display: block;
        }
    }
}

img.ing-header__plus {
    margin: 1rem 0 2rem 0;

    @media (--screen-md-xxl) {
        margin: 4rem 0;
    }
}

.ing-header__tickets {
    font-size: 5rem;
    height: 8rem !important;
    justify-content: center;

    @media (--screen-md-xxl) {
        order: -1;
        width: 100%;
        max-width: 40rem;
    }
}

.ing-block__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    row-gap: 2rem;
    list-style: none;
    position: relative;
    z-index: 1;
    margin: 2rem auto;

    @media (--screen-xl-xxl) {
        gap: 4rem;
        row-gap: 4rem;
        margin: 4rem auto;
    }
}


.ing-block {
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 2rem;
    color: var(--color-purple);
    background-color: var(--color-white);
    text-align: center;

    @media (--screen-lg) {
        padding: 4rem;
        max-width: 45rem;
    }

    @media (--screen-xl-xxl) {
        padding: 4rem;
        max-width: 48rem;
    }

    img {
        width: 100%;
        max-width: 27rem;
    }

    :last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    img {
        margin-bottom: 2rem;

        @media (--screen-lg) {
            margin-bottom: 4rem;
        }
    }

    h3 {
        @media (--screen-md-xxl) {
            height: 6rem;
        }
    }
}

.ing-intro {
    padding: 2rem;
    max-width: 152rem;
    margin: 0 auto;
    box-sizing: border-box;

    @media (--screen-lg-xxl) {
        padding: 4rem;
    }

    :first-child {
        margin-top: 0;
        padding-top: 0;
    }

    :last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.ing-lang {
    margin: 5rem 0 2.5rem;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
    row-gap: 1rem;
    list-style: none;

    li {
        display: inline-block;

        &::after {
            content: '|';
            padding-left: 1rem;
            display: inline-block;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }

    a {
        text-decoration: none;
        transition: all .3s ease;
        font-weight: 700;
        color: var(--color-white);

        &:hover, &:focus {
            color: var(--color-orange);
        }

        &.-active {
            text-decoration: underline;
            color: var(--color-purple);
        }
    }
}
