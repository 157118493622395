.cta {
    .container {
        position: relative;
        height: 100%;
        padding: 4rem 2rem;
    }
}

.cta__list {
    width: 100%;
    max-width: 120rem;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    z-index: 1;
    gap: 2rem;
    row-gap: 2rem;
    list-style: none;

    li {
        max-width: 36rem;
    }

    @media (--screen-sm-xxl) {
        li {
            flex-basis: calc(50% - 2rem);
        }
    }

    @media (--screen-lg-xxl) {
        li {
            flex-basis: 36rem;
        }
    }

    @media (--screen-xl-xxl) {
        gap: 4rem;
        row-gap: 4rem;
    }
}

.cta-item {
    width: 100%;
    position: relative;
    display: block;
    color: var(--color-white);
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    transition: all .3s ease;
}

.cta-item__image {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    transition: all .3s ease;

    .cta-item:hover &, .cta-item:focus & {
        opacity: .7;
    }
}

.cta-item__title {
    display: block;
    color: var(--color-white);
    font-size: 2.5rem;
    text-align: left;
    text-transform: uppercase;
    margin: 0 0 1rem;
    transition: all .3s ease;

    .cta-item:hover &, .cta-item:focus & {
        color: var(--color-yellow);
    }
}
