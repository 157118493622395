.news {
    max-width: 160rem;
    margin: 0 auto;
    padding: 0 2rem;

    @media (--screen-lg-xxl ) {
        padding: 0 4rem;
    }

    .container {
        position: relative;
        height: 100%;
        background-color: var(--color-white);
        padding: 2rem;

        @media (--screen-xxl) {
            padding: 4rem;
        }
    }

    .pagination {
        margin-bottom: 6rem;

        @media (--screen-lg-xxl) {
            margin-bottom: 16rem;
        }
    }
}

.news-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 4rem;
    row-gap: 1rem;
    position: relative;
    z-index: 1;
    list-style: none;

    @media (--screen-md-xxl) {
        row-gap: 4rem;
    }

    li {
        flex-basis: 35rem;
    }
}

.news-item {
    width: 100%;
    position: relative;
    display: block;
    font-size: 0;
    text-decoration: none;
}

.news-item__content {
    width: 100%;
    padding: 1rem 0 0;
    text-align: left;
    color: var(--color-purple);
    z-index: 2;
    box-sizing: border-box;
}

.news-item__title {
    font-size: 2rem;
    color: var(--color-purple);
    font-family: var(--font-family);
    display: block;
    font-weight: 800;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1.25;
    margin: 0 0 1rem;

    .news-item:hover &, .news-item:focus & {
        color: var(--color-orange);
    }
}

.news-item__date {
    display: block;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 800;
    color: var(--color-orange);
    font-family: var(--font-family);
    transition: all .3s ease;

    .news-item:hover &, .news-item:focus & {
        color: var(--color-purple);
    }
}

.news-item__image {
    width: 100%;
    transition: all .3s ease;

    .news-item:hover &, .news-item:focus & {
        opacity: .7;
    }
}
