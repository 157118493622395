a {
    color: var(--color-purple);
    text-decoration: underline;
    font-weight: 400;
    font-family: var(--font-family);

    &:hover, &:focus {
        text-decoration: none;
    }
}
