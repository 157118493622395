.dropdown {
    position: relative;
}

.dropdown__content {
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background: var(--color-white);
    padding: 1.6rem;
    width: 8rem;
    opacity: 0;
    transition: opacity .3s ease;
    z-index: 5;

    &.-calendar {
        width: 20rem;
        border: .2rem solid var(--color-purple);
        margin-top: .8rem;
    }

    .dropdown__toggle.-lang + & {
        width: auto;
        padding: .8rem;
        border: 1px solid var(--color-purple);
        border-top: none;
    }

    .dropdown.-open & {
        display: block;
        opacity: 1;
    }

    .header.-menu & {
        @media (--screen-xs-xl) {
            display: block;
            opacity: 1;
            width: auto;
            position: relative;
            top: auto;
            right: auto;
        }
    }
}

.dropdown__toggle {
    height: 100%;
    outline: none;
    background: var(--color-white);
    border: 0;
    font-size: 1.3rem;
    text-transform: uppercase;
    color: var(--color-purple);
    font-family: var(--font-family);
    padding: 0 4.8rem 0 1.5rem;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 700;

    &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: .5rem solid transparent;
        border-right: .5rem solid transparent;

        border-top: .5rem solid var(--color-purple);
        position: absolute;
        right: 2.5rem;
        top: 50%;
        transform: translateY(-50%);
    }

    &.-calendar {
        background: transparent;
        min-height: 2.4rem;
        color: var(--color-orange);
        font-size: 1.8rem;
        padding: 0 3.6rem 0 0;

        &::after {
            background-color: var(--color-purple);
            mask-image: url(calendar.svg);
            mask-repeat: no-repeat;
            mask-position: center center;
            width: 2rem;
            height: 2rem;
            border: 0;
            right: 0;
        }

        span {
            color: var(--color-purple);
        }

        @media (--screen-xs) {
            font-size: 1.6rem;
            padding: 0 2.6rem 0 0;
        }
    }

    &.-lang {
        background-color: transparent;
        font-size: 1.6rem;
        color: var(--color-white);
        padding: 0 .8rem;

        &::after {
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            position: relative;
            right: unset;
            top: unset;
            transform: none;

            background: currentcolor;
            mask-image: url(arrow-down.svg);
            mask-repeat: no-repeat;
            mask-position: center center;
        }

        @media (--screen-xxl) {
            font-size: 2rem;
        }
    }

    .dropdown.-open & {
        background-color: var(--color-white);

        &::after {
            transform: translateY(-50%) rotate(180deg);
        }

        &.-calendar {
            background-color: transparent;

            &::after {
                transform: translateY(-50%);
            }
        }

        &.-lang {
            background-color: var(--color-blue);
            border: 1px solid var(--color-purple);
            border-bottom: none;

            &::after {
                transform: rotate(180deg);
            }
        }
    }

    .header.-menu & {
        @media (--screen-xs-xl) {
            display: none;
        }
    }
}

.dropdown-list {
    display: block;
    margin: 0;
    padding: 0 1rem;
    list-style: none;
    box-sizing: border-box;

    li {
        display: block !important;
        width: auto !important;
        text-align: left !important;
        padding: .5rem 0 !important;
    }
}

.dropdown-list__item  {
    text-transform: uppercase;
    text-decoration: none;
    transition: all .3s ease;
    position: relative;
    align-items: center;
    padding-left: 3rem;
    font-weight: 700;

    &:hover, &:focus {
        color: var(--color-orange);
    }

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: .4rem;
        width: .6rem;
        height: .6rem;
        border-radius: 50%;
        background-color: var(--color-blue);
        display: none;
    }

    &.-active {
        color: var(--color-orange);

        &::before {
            display: block;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        display: block;
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
        border: .2rem solid var(--color-blue);
        box-sizing: border-box;
    }
}
