.social__links {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 0 0 2.5rem;
    padding: 0;
    list-style: none;

    @media (--screen-sm-xxl) {
        justify-content: flex-start;
    }

    .artist-detail__meta & {
        margin: 0;

        @media (--screen-md-xxl) {
            margin: 0 0 0 3rem;
        }
    }

    li {
        flex-basis: auto;
        padding: 0;
        font-size: 0;
    }
}

.social__links__item {
    transition: all .3s ease;

    &:hover, &:focus {
        opacity: .7;
    }

    img {
        .footer & {
            filter: brightness(0) invert(1);
        }
    }
}
