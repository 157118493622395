.accordion {
    margin: 0;
    position: relative;
    z-index: 1;
    background-color: var(--color-white);
    padding: 0 2rem 2rem;
    box-sizing: border-box;

    @media (--screen-xl-xxl) {
        padding: 0 4rem 4rem;
    }
}

.accordion__title {
    text-transform: uppercase;
    color: var(--color-purple);
    font-size: 2.2rem;
    font-weight: 800;
    margin-bottom: 2.5rem;
    display: block;
}

.accordion-list {
    max-width: 80rem;
    margin: 0;
    padding: 0;
    list-style: none;
    border-top: .1rem solid var(--color-purple);
}

.accordion-item__content {
    padding-bottom: 3.2rem;

    :last-child {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    * {
        font-family: var(--font-family) !important;
    }
}

.accordion-item {
    border-bottom: .1rem solid var(--color-purple);

    summary {
        display: block;
        padding: 3rem 3rem 3rem 0;
        cursor: pointer;
        color: var(--color-purple);
        font-size: 1.6rem;
        position: relative;
        transition: all .3s ease;
        font-weight: 700;

        &::after {
            content: '';
            display: block;
            background-color: var(--color-purple);
            mask-image: url(plus.svg);
            mask-repeat: no-repeat;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            right: 0;
            width: 2.4rem;
            height: 2.4rem;
            transition: all .3s ease;
        }

        &:hover, &:focus {
            color: var(--color-orange);

            &::after {
                background-color: var(--color-orange);
            }
        }
    }

    &[open] > summary {
        &::after {
            transform: translateY(-50%) rotate(0);
            mask-image: url(min.svg);
        }
    }
}
