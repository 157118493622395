ol {
    margin: 1rem 0 3.2rem;
    padding: 0 0 0 1.7rem;
    line-height: 1.67;

    .article__content & {
        margin-left: auto;
        margin-right: auto;

        .site-app & {
            margin-left: 0;
        }
    }

    li {
        margin: 0 0 .4rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    li > & {
        margin: .8rem 0 0;
    }
}
