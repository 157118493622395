.highlights {
    max-width: 160rem;
    margin: 0 auto 2rem;
    padding: 0 2rem;

    @media (--screen-lg-xxl ) {
        margin-bottom: 4rem;
        padding: 0 4rem;
    }

    .container {
        position: relative;
        height: 100%;
        padding: 0;
        //background-color: var(--color-white);
        //padding: 2rem;

        //@media (--screen-xxl) {
        //    padding: 4rem;
        //}
    }
}

.highlights__title {
    display: flex;
    justify-content: center;

    .title-2 {
        color: var(--color-white);
        font-style: italic;
    }
}

.highlights-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    row-gap: 2rem;
    position: relative;
    z-index: 1;
    list-style: none;

    li {
        max-width: 36rem;
    }

    @media (--screen-sm-xxl) {
        li {
            flex-basis: calc(50% - 2rem);
        }
    }

    @media (--screen-lg-xxl) {
        li {
            flex-basis: 36rem;
        }
    }

    @media (--screen-xl-xxl) {
        gap: 4rem;
        row-gap: 4rem;
    }
}

.highlights-item {
    width: 100%;
    position: relative;
    display: block;
    font-size: 0;
    text-decoration: none;
}

.highlights-item__content {
    width: 100%;
    padding: 1rem 0 0;
    text-align: left;
    color: var(--color-purple);
    z-index: 2;
    box-sizing: border-box;
}

.highlights-item__title {
    font-size: 2.5rem;
    color: var(--color-white);
    font-family: var(--font-family);
    display: block;
    font-weight: 700;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1.25;
    margin: 0 0 1rem;

    .highlights-item:hover &, .highlights-item:focus & {
        color: var(--color-yellow);
    }
}

.highlights-item__date {
    display: block;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 800;
    color: var(--color-purple);
    font-family: var(--font-family);
    transition: all .3s ease;

    .highlights-item:hover &, .highlights-item:focus & {
        color: var(--color-white);
    }
}

.highlights-item__image {
    width: 100%;
    transition: all .3s ease;

    .highlights-item:hover &, .highlights-item:focus & {
        opacity: .7;
    }
}
