.intro {
    color: var(--color-black);
    font-size: 1.8rem;
}

@media (--screen-medium-large) {
    .intro {
        font-size: 2rem;
    }
}
