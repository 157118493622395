.logo {
    display: inline-block;
    position: relative;
    font-size: 0;
    z-index: 7;

    &::before {
        transition: all .3s ease;
        content: '';
        display: block;
        background-image: url(logo.png);
        background-size: contain;
        background-repeat: no-repeat;
        width: 21.7rem;
        height: 8.1rem;
        z-index: 3;
    }

    small {
        display: block;
        font-size: 0;
        transition: all .3s ease;
        background-image: url(date.png);
        background-size: contain;
        background-repeat: no-repeat;
        width: 21.7rem;
        height: 4.2rem;
        z-index: 1;

        .site-fr & {
            background-image: url(date.png);
        }
    }

    &.-header {
        display: flex;
        flex-direction: column;
        margin-right: auto;
        width: auto;
        height: auto;

        &::before {
            width: 14.5rem;
            height: 5.4rem;
        }

        small {
            width: 14.9rem;
            height: 2.8rem;
        }

        @media (--screen-lg-xxl) {
            flex-direction: row;
            align-items: center;
            column-gap: 1rem;

            &::before {
                width: 13rem;
                height: 4.9rem;
            }

            small {
                width: 18rem;
                height: 3.5rem;
            }
        }

        @media (--screen-xxl) {
            &::before {
                width: 15.8rem;
                height: 5.9rem;
            }

            small {
                width: 26.3rem;
                height: 5rem;
            }
        }

        @media only screen and (min-width: 1600px) {
            &::before {
                width: 21.6rem;
                height: 8.1rem;
            }

            small {
                width: 30.4rem;
                height: 5.8rem;
            }
        }
    }
}
