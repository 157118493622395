h1,
.title-1,
h2,
.title-2,
h3,
.title-3,
h4,
.title-4,
h5,
.title-5,
h6,
.title-6 {
    margin: 1rem 0 2rem;
    font-weight: 700;
    line-height: 1.2;
    color: var(--color-purple);
    font-family: var(--font-family);
    display: block;
    position: relative;
    z-index: 1;

    .article__content & {
        color: var(--color-purple);
        text-align: left;
    }
}

h1, .title-1 {
    margin: .8rem auto 3.2rem;
    font-size: 3.4rem;
    text-align: center;

    @media (--screen-lg-xxl) {
        font-size: 5.8rem;
    }

    .article &, .news &, .article-children &, .lineup .container.-title &, .artist-detail & {
        font-size: 4rem;
        position: relative;
        padding-bottom: 4rem;
        text-align: left;
        margin-top: 0;
        margin-bottom: 2rem;
        text-transform: uppercase;
        line-height: 1;

        @media (--screen-lg-xxl) {
            font-size: 6rem;
        }

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: .2rem;
            background-color: var(--color-purple);
            position: absolute;
            left: 0;
            bottom: 2rem;
        }
    }

}

h2, .title-2 {
    font-size: 3.4rem;
    text-align: center;
    text-transform: uppercase;

    .highlights &, .artist-related &, .article-children &, &.media__title {
        font-size: 4rem;
        position: relative;
        padding-bottom: 2rem;
        text-align: left;
        margin-top: 0;

        @media (--screen-lg-xxl) {
            font-size: 6rem;
        }

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: .2rem;
            background-color: var(--color-white);
            position: absolute;
            left: 0;
            bottom: 1rem;
        }
    }

    .article-children & {
        font-size: 3rem;

        @media (--screen-lg-xxl) {
            font-size: 4rem;
        }
    }
}

h3, .title-3 {
    font-size: 2.4rem;
}

h4, .title-4 {
    font-size: 2rem;
}

h5, .title-5 {
    font-size: 1.8rem;
}

h6, .title-6 {
    font-size: 1.5rem;
}
