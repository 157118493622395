.banner {
    max-width: 160rem;
    margin: -2rem auto 2rem;
    padding: 0 2rem;

    @media (--screen-lg-xxl ) {
        margin: -4rem auto 4rem;
        padding: 0 4rem;
    }

    .container {
        position: relative;
        height: 100%;
        //background-color: var(--color-white);
        padding: 2rem;

        @media (--screen-xxl) {
            padding: 4rem;
        }
    }
}

.banner__image {
    display: block;
    width: 100%;
    border: 1px solid var(--color-purple);
}

.banner__video__container {
    position: relative;
    padding-bottom: 56.25%;
    border: 1px solid var(--color-purple);
}

.banner__content {
    display: block;
    background: var(--color-blue);
    padding: 1rem 1.5rem;
    border: 1px solid var(--color-purple);
    box-sizing: border-box;
    margin: 0;
    position: relative;
    z-index: 1;

    text-decoration: none;
    transition: background-color .3s ease;

    &::after {
        position: absolute;
        display: block;
        content: '';
        background-image: url(stars.png);
        background-repeat: no-repeat;
        background-size: contain;
        width: 8.2rem;
        height: 10rem;
        top: -10rem;
        left: -3.8rem;

        @media (--screen-sm-xxl) {
            width: 14.8rem;
            height: 18rem;
            top: -17rem;
            left: -8rem;
        }

        @media (--screen-md-xxl) {
            width: 21.5rem;
            height: 26.1rem;
            top: -23rem;
            left: -17.5rem;
        }

        @media (--screen-lg-xxl) {
            width: 28.6rem;
            height: 34.8rem;
            top: -30rem;
            left: -22rem;
        }
    }

    &[href]:hover, &[href]:focus {
        background-color: var(--color-violet);
    }

    @media (--screen-md-xxl) {
        display: inline-block;
        padding: 1rem 2.5rem;
        margin-top: -7rem;
        margin-inline: 6.6rem;
    }
}

.banner__label {
    color: var(--color-white);
    font-size: 1.6rem;
    font-family: var(--font-family);
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    line-height: 1;
    margin-bottom: 1rem;

    @media (--screen-lg-xxl) {
        font-size: 3.5rem;
    }
}

.banner__title {
    color: var(--color-white);
    font-size: 2.4rem;
    font-family: var(--font-family);
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    line-height: 1;

    @media (--screen-lg-xxl) {
        font-size: 5rem;
    }
}

.banner__video {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    background-size: cover;
    background-position: center;

    overflow: hidden;
    pointer-events: none;

    img {
        display: none;
    }
}

.banner__video__player {
    opacity: 0;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .banner__video.-autoplay-working & {
        opacity: 1;
    }
}
