.lineup {
    max-width: 160rem;
    margin: 0 auto;
    padding: 0 2rem;

    @media (--screen-lg-xxl) {
        padding: 0 4rem;
    }

    .container {
        position: relative;
        height: 100%;
        padding: 0;

        @media (--screen-xxl) {
            padding: 0 4rem;
        }

        &.-title {
            background-color: var(--color-white);
            padding: 2rem;

            @media (--screen-xxl) {
                padding: 4rem;
            }

            margin-bottom: 4rem;
        }

        &.-list {
            padding: 0;
        }
    }
}

.lineup__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    row-gap: 2rem;
    list-style: none;
    position: relative;
    z-index: 1;

    @media (--screen-xl-xxl) {
        gap: 4rem;
        row-gap: 4rem;
    }

    &.-overview {
        margin-bottom: 4rem;
    }
}

.lineup-block {
    width: 100%;
    max-width: 36rem;
    position: relative;

    @media (--screen-sm-xxl) {
        flex-basis: calc(50% - 2rem);
    }

    @media (--screen-lg-xxl) {
        flex-basis: 36rem;
    }
}

.lineup-nav {
    background-color: var(--color-white);
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    display: flex;
    gap: 1.6rem;
    align-items: center;
    margin: 0;
    position: relative;
    z-index: 2;

    @media (--screen-sm-xl) {
        gap: 3rem;
    }

    @media (--screen-xxl) {
        gap: 3rem;
    }

    li {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 3rem;
        text-align: center;
        display: none;

        @media (--screen-xxl) {
            width: auto;
            display: inline-block;
        }

        &.-all {
            margin-right: auto;
            width: auto;
            display: inline-block;
        }

        &.-dropdown {
            width: auto;
            display: inline-block;

            @media (--screen-xxl) {
                display: none;
            }
        }
    }
}

.lineup-nav__item {
    text-decoration: none;
    color: var(--color-purple);
    text-transform: uppercase;
    transition: all .3s ease;
    font-weight: 700;
    font-size: 1.6rem;

    li.-all & {
        text-transform: none;
    }

    &.-active, &:hover, &:focus {
        color: var(--color-orange);
    }
}

.lineup__title {
    display: block;
    text-align: left;
    text-transform: uppercase;
    color: var(--color-white);
    font-style: italic;
    font-size: 3.2rem;
    font-weight: 800;
    margin-bottom: 2.5rem;
    background-color: var(--color-orange);
    border: .1rem solid var(--color-purple);
    padding: .5rem 1rem;
}
