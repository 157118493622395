.pagination {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 4rem;
    width: 100%;
    position: relative;
    z-index: 1;

    color: var(--default-text-color);
    font-size: 1.5rem;

    a {
        color: var(--default-text-color);
        text-decoration: none;

        &[href]:hover, &[href]:focus, &[href]:active {
            border-color: var(--color-gray-cool-4);
            z-index: 1;
        }
    }
}

.pagination__summary {
    width: auto;
    padding: 0 .5rem;
    margin: 0;
    align-self: center;

    text-align: center;
}

.pagination__list {
    display: none;
    align-items: stretch;
    margin: 0;
    padding: 0;
    gap: 1rem;
    row-gap: 1rem;

    list-style: none;
}

.pagination__previous, .pagination__next {
    display: flex;
    align-items: center;
    padding: 0 1.3rem;
    position: relative;
    transition: all .3s ease;

    border: .1rem solid var(--color-grayscale-1);

    &:not([href]) {
        opacity: .6;
    }

    &:hover, &:focus {
        color: var(--color-orange);

        &::before, &::after {
            background-color: var(--color-orange);
        }
    }
}

.pagination__previous {
    padding: 0 1.3rem 0 1.2rem;
    margin-right: 2rem;

    &::before {
        content: '';
        display: block;
        background-color: var(--color-purple);
        mask-image: url(previous.svg);
        mask-repeat: no-repeat;
        mask-size: contain;
        width: 4.4rem;
        height: 1.5rem;
        margin-right: 1rem;
        transition: all .3s ease;
    }
}

.pagination__next {
    padding: 0 1.2rem 0 1.3rem;
    margin-left: 2rem;

    &::after {
        content: '';
        display: block;
        background-color: var(--color-purple);
        mask-image: url(next.svg);
        mask-repeat: no-repeat;
        mask-size: contain;
        width: 4.4rem;
        height: 1.5rem;
        margin-left: 1rem;
        transition: all .3s ease;
    }
}

.pagination__item {
    display: flex;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* stylelint-disable selector-class-pattern */
.pagination__item__link, .pagination__item__spacer {
    display: inline-block;
    box-sizing: border-box;
    margin-left: -.1rem;
    width: 4rem;

    text-align: center;
    line-height: 4rem;
    border-radius: 50%;
    border: .1rem solid var(--color-purple);
    transition: all .3s ease;

    &:hover, &:focus {
        background-color: var(--color-purple);
        color: var(--color-white);
    }

    .pagination__item.-current & {
        border-color: var(--color-orange);
        z-index: 2;
        background-color: var(--color-orange);
        color: var(--color-white);
    }
}
/* stylelint-enable selector-class-pattern */

@media (--screen-lg-xxl) {
    .pagination {
        width: auto;
        justify-content: center;
    }

    .pagination__summary {
        display: none;
    }

    .pagination__list {
        display: flex;
    }
}
