.artist {
    position: relative;
    display: block;
    opacity: 1;
    font-size: 0;
    //background-color: var(--color-white);
    text-decoration: none;
    //padding: 2rem;
    box-sizing: border-box;

    //@media (--screen-xl-xxl) {
    //    padding: 2rem;
    //}

    .lineup__list.-overview & {
        width: 100%;
        max-width: 35rem;
    }

    .lineup__list.-day & {
        width: 100%;
        max-width: 35rem;

        @media (--screen-xs-sm) {
            padding: 2rem;
            background: var(--color-white);

            .artist__content {
                margin-top: 0;
            }
        }
    }

    &.-pending {
        display: none;
        opacity: 1;
    }

    &.-hidden {
        transition: opacity 1s ease;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        pointer-events: none;
    }

    &.-more {
        transition: all .3s ease;
        padding-top: 100%;
        background-color: transparent;
        background-image: url(more.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        &:hover, &:focus {
            opacity: .7;
        }

        .site-fr & {
            background-image: url(more-fr.svg);
        }

        .site-en & {
            background-image: url(more-en.svg);
        }
    }

    figure {
        position: relative;
    }

    &:hover, &:focus {
        .artist__title {
            color: var(--color-yellow);
        }

        .artist__description {
            color: var(--color-white);
        }
    }
}

.artist__image {
    width: 100%;
    transition: all .3s ease;

    .lineup__list.-overview & {
        display: block;

        @media (--screen-md-xxl) {
            display: block;
        }
    }

    .lineup__list.-day & {
        display: none;

        @media (--screen-md-xxl) {
            display: block;
        }
    }

    .artist:hover &, .artist:focus & {
        opacity: .7;
    }
}

.artist__content {
    width: 100%;

    .artist.-more & {
        display: none;
    }

    .lineup__list.-day & {
        @media (--screen-xs-sm) {
            position: relative;
            bottom: 0;
        }
    }
}

.artist__title {
    display: block;
    color: var(--color-white);
    font-size: 2.5rem;
    text-align: left;
    text-transform: uppercase;
    margin: 0 0 1rem;
    transition: all .3s ease;
}

.artist__description {
    padding: 0;
    box-sizing: border-box;
    color: var(--color-purple);
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    row-gap: .5rem;
    align-items: flex-start;
    transition: all .3s ease;
    font-size: 1.6rem;
}

.artist__time, .artist__stage, .artist__empty {
    display: inline-block;
    position: relative;
    text-align: center;
    padding: 0;
    font-size: 1.4rem;
    font-weight: 700;

    .artist-detail & {
        color: var(--color-orange);
    }

    &.-purple {
        margin-top: 1rem;

    }

    + .artist__stage {
        .artist-detail__timing & {
            padding-left: 0;
        }
    }

    .artist-detail__timing & {
        font-size: 1.6rem;
    }
}

.artist__empty {
    height: 2.4rem;
    width: 1.9rem;
    padding: 0;
    font-style: italic;
    font-size: 1.4rem;

    &::before {
        display: none;
    }
}

.artist__item__hour {
    display: inline-block;
    position: relative;
    text-align: center;
    padding: 0 0 0 2.5rem;

    .lineup__list & {
        padding: 0;
    }

    @media (--screen-md-xxl) {
        .artist-detail__timing & {
            display: inline-block;
        }
    }
}

.artist-detail {
    max-width: 160rem;
    margin: 0 auto;
    padding: 0 2rem;

    @media (--screen-lg-xxl ) {
        padding: 0 4rem;
    }

    .container {
        position: relative;
        height: 100%;
        background-color: var(--color-white);
        padding: 2rem 2rem 4rem;

        @media (--screen-xxl) {
            padding: 4rem 4rem 8rem;
        }
    }
}

.artist-detail__content {
    position: relative;
    z-index: 1;
}

.artist-detail__meta {
    display: flex;
    margin: 0 auto 3rem;
    max-width: 80rem;
    color: var(--color-purple);
    align-items: center;
    list-style: none;
    flex-wrap: wrap;
    row-gap: 1rem;
    padding: 0;

    li {
        padding: 0;
        margin-bottom: 1rem;
        flex-basis: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        flex-direction: column;

        @media (--screen-md-xxl) {
            flex-direction: row;
            margin-bottom: 0;
        }
    }

    .label {
        text-transform: uppercase;
        padding: 0 0 1rem;
        font-weight: 800;
        color: var(--color-orange);

        @media (--screen-md-xxl) {
            padding: 0 1rem 0;
        }
    }
}

.artist-detail__image {
    max-width: 98rem;
    margin: 0 auto 5rem;
    position: relative;
    font-size: 0;

    img {
        width: 100%;
    }

    .button {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.artist-detail__timing {
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
}

.artist-related {
    background-color: var(--color-white);
    padding: 6rem 0 0;

    @media (--screen-md-xl ) {
        padding: 8rem 0 0;
    }

    @media (--screen-xxl) {
        padding: 10rem 0 0;
    }

    &.-banner {
        padding: 6rem 0 0;
    }

    .container {
        position: relative;
        height: 100%;
        overflow: hidden;
    }
}
