hr {
    margin: 4rem 0;
    border: .1rem solid var(--color-white);
    display: block;
    position: relative;
    overflow: visible;

    &.-small {
        margin: 0 0 1rem;

        @media (--screen-sm-xxl) {
            display: none;
        }
    }

    &.-small-bottom {
        @media (--screen-xs) {
            margin-bottom: 2rem;
        }
    }

    .article &, .article__content & {
        border-color: var(--color-purple);
    }
}
