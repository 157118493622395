.footer {
    max-width: 160rem;
    margin: 0 auto;
    color: var(--color-white);
    padding: 3rem 2rem 0;

    @media (--screen-sm-md) {
        padding: 5rem 2rem 0;
    }

    @media (--screen-lg) {
        padding: 8rem 4rem 0;
    }

    @media (--screen-xl-xxl) {
        padding: 8rem 4rem 0;
    }

    .container {
        position: relative;
        z-index: 1;
        padding: 0;
    }

    .site-app & {
        display: none;
    }
}

.footer__top {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    text-align: center;
    row-gap: 4rem;

    @media (--screen-sm-xxl) {
        text-align: left;
    }

    @media (--screen-lg-xxl) {
        flex-wrap: nowrap;
    }

    > * {
        flex-basis: 100%;

        @media (--screen-sm-md ) {
            flex-basis: 50%;
        }

        @media (--screen-lg-xxl) {
            flex-basis: 25%;
        }
    }

    .logo {
        display: none;
        flex: 0 0 24.7rem;

        @media (--screen-sm-xxl) {
            display: inline-block;
        }
    }
}

.footer__main {
    box-sizing: border-box;
}

.footer__bottom {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    font-size: 1.3rem;
    padding: 0 0 4rem;
    box-sizing: border-box;
    flex-direction: column;

    @media (--screen-xxl) {
        flex-direction: row;
    }
}

.footer__legal {
    display: flex;
    gap: 3rem;
    row-gap: 0;
    color: var(--color-white);
    margin: 0;
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 2rem;
    list-style: none;

    @media (--screen-sm-xxl) {
        row-gap: 1rem;
    }

    @media (--screen-xxl) {
        justify-content: flex-start;
        font-size: 1.6rem;
    }
}

.footer__copy {
    display: flex;
    gap: 3rem;
    row-gap: 1rem;
    color: var(--color-blue);
    margin: 0;
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;

    @media (--screen-xxl) {
        justify-content: flex-start;
        margin-left: auto;
    }
}

.footer__socials {
    box-sizing: border-box;

    @media (--screen-sm-md ) {
        padding-left: 6rem;
    }

    @media (--screen-sm-xxl) {
        order: 4;
    }
}

.footer__nav {
    box-sizing: border-box;

    ul {
        margin: 0;
        padding: 0;
    }

    @media (--screen-sm-xxl) {
        padding-left: 6rem;
    }

    &.-info {
        padding-left: 0;
    }
}

.footer__title {
    font-family: var(--font-family);
    font-size: 2rem;
    font-weight: 700;
    display: block;
    margin-bottom: 2.5rem;
    text-transform: uppercase;
    color: var(--color-purple);
}

.footer__lang {
    margin: 2.5rem 0;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
    row-gap: 1rem;
    list-style: none;

    @media (--screen-sm-xxl) {
        justify-content: flex-start;
    }

    li {
        display: inline-block;
        &::after {
            content: '|';
            padding-left: 1rem;
            display: inline-block;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }

    a {
        color: var(--color-white);
        text-decoration: none;
        transition: all .3s ease;
        font-weight: 700;

        &:hover, &:focus {
            color: var(--color-yellow);
        }

        &.-active {
            text-decoration: underline;
            color: var(--color-white);
        }
    }
}
