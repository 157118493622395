.button {
    display: inline-flex;
    margin: 0;
    padding: 1.6rem;
    background-color: var(--color-yellow);
    color: var(--color-purple);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.6rem;
    text-decoration: none;
    line-height: 1.15;
    border: .1rem solid var(--color-purple);
    cursor: pointer;
    position: relative;
    transition: all .3s ease;
    align-items: center;
    box-sizing: border-box;

    .button-container & {
        margin-bottom: 1rem;
    }

    &:hover, &:focus {
        background-color: var(--color-purple);
        color: var(--color-yellow);
    }

    &.-tickets {
        height: 5.5rem;

        .header & {
            display: none;

            @media (--screen-md-xxl) {
                display: inline-flex;
                margin-left: 1.8rem;
            }
        }

        .header__nav & {
            display: inline-flex;

            @media (--screen-md-xxl) {
                display: none;
            }
        }
    }

    &.-green {
        background-color: var(--color-green);
        color: var(--color-white);

        &:hover, &:focus {
            color: var(--color-green);
            background-color: var(--color-yellow);
        }
    }

    &.-blue {
        background-color: var(--color-blue);
        color: var(--color-purple);

        &:hover, &:focus {
            color: var(--color-blue);
            background-color: var(--color-purple);
        }
    }

    &.-purple {
        padding: 1.6rem 3rem;
        background-color: var(--color-purple);
        color: var(--color-white);

        &:hover, &:focus {
            color: var(--color-purple);
            background-color: var(--color-yellow);
        }
    }

    &.-full {
        display: block;
        text-align: center;
    }
}
