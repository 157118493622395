.breadcrumb {
    text-align: center;

    .article &, .artist-detail & {
        position: absolute;
        width: 100%;
        left: 0;
        top: -4rem;
    }
}

.breadcrumb__link {
    display: inline-flex;
    align-items: center;
    color: var(--color-purple);
    font-size: 1.6rem;
    font-family: var(--font-family);
    text-decoration: none;
    transition: all .3s ease;

    &::before {
        content: '';
        display: block;
        background-color: var(--color-purple);
        mask-image: url(arrow.svg);
        mask-repeat: no-repeat;
        mask-size: contain;
        width: 4.4rem;
        height: 1.5rem;
        margin-right: 1rem;
        transition: all .3s ease;
    }

    &:hover, &:focus {
        color: var(--color-orange);

        &::before {
            background-color: var(--color-orange);
        }
    }
}
