body {
    font-family: var(--font-family);
    font-size: 1.8rem;
    line-height: 1.56;
    color: var(--color-purple);
    background-color: var(--color-rouge);
    margin: 0;
    padding: 0;
    overflow-x: hidden;

    .site-app & {
        background-color: var(--color-white);
    }

    &::after, &::before {
        display: block;
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -2;

        .site-app & {
            display: none;
        }
    }

    &::before {
        background-image: url(noise.png);
        z-index: -1;
        opacity: .25;
    }

    &::after {
        background-image: linear-gradient(135deg, var(--color-violet) 0%, var(--color-blue) 100%);
    }
}
