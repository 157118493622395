.article__container {
    padding: 0;

    .container {
        position: relative;
        height: 100%;
    }
}

.article-children {
    margin: 0;
    padding: 2rem;
    background-color: var(--color-white);

    .article + & {
        margin-top: 4rem;
    }

    @media (--screen-lg-xxl ) {
        padding: 4rem;
    }
}

.article-children__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4rem;
    row-gap: 1rem;
    position: relative;
    z-index: 1;
    list-style: none;

    @media (--screen-md-xxl) {
        row-gap: 4rem;
    }

    li {
        flex-basis: 35rem;
    }

    &.-bottom {
        margin-bottom: 0;
    }
}

.article-child {
    width: 100%;
    position: relative;
    display: block;
    color: var(--color-purple);
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 800;
    text-align: left;
    text-decoration: none;
    transition: all .3s ease;
    line-height: 1.25;

    &:hover, &:focus {
        color: var(--color-orange);
    }
}

.article-child__image {
    width: 100%;
    margin-bottom: 0;
    transition: all .3s ease;

    .article-child:hover &, .article-child:focus & {
        opacity: .7;
    }
}

.article {
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 2rem 2rem 4rem;
    color: var(--color-purple);
    background-color: var(--color-white);

    @media (--screen-lg-xxl) {
        padding: 4rem 4rem 8rem;
    }

    .site-app & {
        padding: 2rem;

        @media (--screen-lg-xxl) {
            padding: 4rem;
        }
    }
}

.article__content {
    color: var(--color-purple);
    max-width: 80rem;
    margin: 0 auto;

    .site-app & {
        max-width: 100%;
    }

    p, ul, ol, h1, h2, h3, h4, h5, h6, .embeddedContent, hr, .CookieDeclaration {
        max-width: 80rem;
    }

    iframe {
        width: 100%;
        height: calc(80rem * (9/16));
    }

    :last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .artist-detail & {
        max-width: 80rem;
        margin: 0 auto;
    }
}

.article-aside {
    display: block;
    margin: 2rem 0 0;
    position: relative;
    z-index: 1;
    background-color: var(--color-white);

    .container {
        padding: 2rem;

        @media (--screen-lg-xxl) {
            padding: 4rem;
        }
    }

    @media (--screen-lg-xxl) {
        margin: 4rem 0 0;
    }
}

.article__time {
    display: block;
    max-width: 80rem;
    margin: 0 0 4rem;
    text-align: left;
    color: var(--color-orange);
    font-family: var(--font-family);
    font-size: 2rem;
    font-weight: 800;
}

.article__content__video {
    max-width: 80rem;
    margin: 0 auto;
}

.article__content__spotify {
    max-width: 80rem;
    margin: 4rem auto 0;

    iframe {
        height: 38rem;

        @media (--screen-md-xxl) {
            height: 8rem;
        }
    }
}

.ratio {
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative;

    &.-r16-9 {
        padding-top: 56.25%; /* 16:9 Aspect Ratio */
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--color-purple);
        opacity: .1;
        z-index: -1;
    }
}

.ratio__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100% !important;
}

.CookieDeclarationTableCell, .CookieDeclarationTableHeader {
    border-color: var(--color-purple) !important;
}

.CookieDeclarationTypeHeader {
    font-size: 2.4rem !important;
}

.CookieDeclarationType {
    padding: 0 !important;
    border: 0 !important;
}

.CookieDeclarationTable {
    border: .1rem solid var(--color-purple) !important;
}
