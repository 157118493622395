.partners {
    text-align: center;
}

.partners__item {
    height: 135px;
    width: 60%;
    display: inline-block;
    margin-right: 1%;
    margin-left: 1%;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0 10px;
    box-sizing: border-box;

    position: relative;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }

    &.-small {
        width: 58%;
    }

    &.-medium {
        width: 62%;
    }

    &.-large {
        width: 95%;
    }

    &:hover, &:focus {
        opacity: .7;
    }
}

.partners__title {
    display: block;
    text-align: left;
    text-transform: uppercase;
    color: var(--color-white);
    font-style: italic;
    font-size: 3.2rem;
    font-weight: 800;
    margin: 2rem 0;
    background-color: var(--color-orange);
    border: .1rem solid var(--color-purple);
    padding: .5rem 1rem;

    &:first-of-type {
        margin-top: 0;
    }
}

@media (--screen-sm-xxl) {
    .partners__item {
        width: 26%;
        margin-right: 3%;
        margin-left: 3%;

        &.-small {
            width: 24%;
        }

        &.-medium {
            width: 28%;
        }

        &.-large {
            width: 60%;
        }
    }
}

@media (--screen-md-xxl) {
    .partners__item {
        width: 20%;
        margin-right: 2.5%;
        margin-left: 2.5%;

        &.-small {
            width: 18%;
        }

        &.-medium {
            width: 22%;
        }

        &.-large {
            width: 45%;
        }
    }
}

@media (--screen-lg-xxl) {
    .partners__item {
        width: 12%;
        margin-right: 2%;
        margin-left: 2%;

        &.-small {
            width: 10%;
        }

        &.-medium {
            width: 14%;
        }

        &.-large {
            width: 28%;
        }
    }
}
