.partners-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    padding: 0;
    margin: 0 1rem;
    gap: 1rem;
    list-style: none;

    @media (--screen-md-xxl) {
        margin: 0;
    }

    li {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
    }
}

.partners-list__item {
    display: block;
    max-width: 8rem;
    transition: all .3s ease;

    @media (--screen-md-xxl) {
        max-width: 10rem;
    }

    img {
        max-width: 100%;
        filter: brightness(0) invert(1);
    }

    &:hover, &:focus {
        opacity: .7;
    }

    &.-medium {
        max-width: 8rem;
    }

    &.-small{
        max-width: 6rem;
    }

    &.-filter {
        img {
            filter: none;
        }
    }
}
