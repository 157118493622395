/* Image Gallery */
@import '~photoswipe/dist/default-skin/default-skin.css';
@import '~photoswipe/dist/photoswipe.css';

.media {

}

.media-grid {
    margin: 0;
    padding: 0;
    list-style: none;
    columns: 2;
    column-gap: 2rem;

    li {
        margin-bottom: 2rem;
    }

    @media (--screen-sm-lg ) {
        columns: 3;
    }

    @media (--screen-lg-xxl ) {
        columns: 6;
        column-gap: 4rem;
    }
}

.media-grid__item {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;

    img {
        width: 100%;
        display: block;
    }

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        opacity: 0;
        transition: all .3s ease-in-out;
    }

    &::after {
        background-color: var(--color-orange);
        opacity: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &::before {
        top: 50%;
        left: 50%;
        z-index: 2;
        width: 1.8rem;
        height: 1.8rem;
        transform: translate(-50%, -50%);
        background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE4IiB2aWV3Qm94PSIwIDAgMTggMTgiIHdpZHRoPSIxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtNjE2LjY2OTk2NCAyMzMwLjIzNDA1IDQuMDQzNSA0LjA0MzVjLjM5MDUyNC4zOTA1Mi4zOTA1MjQgMS4wMjM2OSAwIDEuNDE0MjFsLS4wMjE3MDUuMDIxN2MtLjM5MDUyNC4zOTA1My0xLjAyMzY4OS4zOTA1My0xLjQxNDIxMyAwbC00LjA0MzUtNC4wNDM1Yy0xLjI4MTE2NC45Nzg4My0yLjg4MjE1MyAxLjU2MDI1LTQuNjE4OTQgMS41NjAyNS00LjIwNTcwNyAwLTcuNjE1MTA2LTMuNDA5NC03LjYxNTEwNi03LjYxNTEgMC00LjIwNTcxIDMuNDA5Mzk5LTcuNjE1MTEgNy42MTUxMDYtNy42MTUxMSA0LjIwNTcwNiAwIDcuNjE1MTA1IDMuNDA5NCA3LjYxNTEwNSA3LjYxNTExIDAgMS43MzY3OC0uNTgxNDI0IDMuMzM3NzctMS41NjAyNDcgNC42MTg5NHptLTYuMDU0ODU4Ljk2NTQ3YzMuMDg0MTg1IDAgNS41ODQ0MTEtMi41MDAyMyA1LjU4NDQxMS01LjU4NDQxIDAtMy4wODQxOS0yLjUwMDIyNi01LjU4NDQyLTUuNTg0NDExLTUuNTg0NDJzLTUuNTg0NDExIDIuNTAwMjMtNS41ODQ0MTEgNS41ODQ0MmMwIDMuMDg0MTggMi41MDAyMjYgNS41ODQ0MSA1LjU4NDQxMSA1LjU4NDQxeiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjAzIC0yMzE4KSIvPjwvc3ZnPg==');
    }

    &:hover,
    &:focus {
        &::after {
            opacity: .4;
        }
        &::before {
            opacity: 1;
        }
    }
}

.media__title {
    font-size: 3.2rem !important;
}

.media-list {
    max-width: 80rem;
    margin: 0;
    padding: 0;
    border-bottom: .1rem solid var(--color-purple);

    li {
        border-top: .1rem solid var(--color-purple);
        display: block;
    }
}

.media-list__item {
    display: flex;
    padding: 2.5rem 0 2.5rem 9rem;
    text-decoration: none;
    align-items: center;
    justify-content: space-between;
    position: relative;
    box-sizing: border-box;

    span {
        text-decoration: underline;
        transition: all .3s ease;

        &.-white {
            text-decoration: none;
            color: var(--color-white);
        }

        &.-button {
            text-decoration: none;
            color: var(--color-white);
            background: var(--color-purple);
            text-transform: uppercase;
            display: inline-flex;
            height: 4rem;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding: 1rem 2rem;
            box-sizing: border-box;
            border-radius: 2rem;
            line-height: 1;
        }
    }

    &::before, &::after {
        content: '';
        display: block;
        width: 5rem;
        height: 5rem;
        background-color: var(--color-purple);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        transition: all .3s ease;
    }

    &::after {
        background-color: var(--color-white);
        mask-image: url(file.svg);
        mask-repeat: no-repeat;
        mask-position: center center;
    }

    &:hover, &:focus {
        span {
            text-decoration: none;

            &.-button {
                background: var(--color-white);
                color: var(--color-purple);
            }
        }

        &::before {
            background-color: var(--color-white);
        }
        &::after {
            background-color: var(--color-purple);
        }
    }
}

.pswp {
    z-index: 9999;

    img {
        max-width: none;
        object-fit: contain;
    }
}

.pswp__caption, .pswp__caption__center {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 1.6rem;
}
