.newsletter {
    max-width: 160rem;
    margin: 0 auto;

    @media (--screen-lg-xxl ) {
        margin: 4rem auto 0;
    }

    .container {
        position: relative;
        height: 100%;
        padding: 0;
    }
}

.newsletter__box {
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 0 0;
    width: 100%;
    max-width: 168rem;
    padding: 0 4rem 14rem;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (--screen-xl-xxl) {
        gap: 4rem;
        padding: 26rem 12rem;
        flex-direction: row;
        align-items: center;
        background-image: url(bg.jpg);
        background-position: center center;
    }
}

.newsletter__title {
    font-size: 4rem;
    color: var(--color-white);
    font-family: var(--font-family);
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 1rem;
    line-height: 1;

    @media (--screen-xl-xxl) {
        font-size: 9rem;
    }
}

.newsletter__info {
    font-size: 1.8rem;
    color: var(--color-white);
    font-family: var(--font-family);
    text-transform: uppercase;
    background-image: url(bg.jpg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center center;
    padding: 23rem 2rem 4rem;
    box-sizing: border-box;
    margin: 0 -4rem;
    width: calc(100% + 8rem);

    @media (--screen-xl-xxl) {
        max-width: 68rem;
        font-size: 2rem;
        background: none;
        padding: 0;
        width: 100%;
        margin: 0;
    }
}

.newsletter__form {
    background: var(--color-yellow);
    color: var(--color-purple);
    width: calc(100% + 8rem);
    box-sizing: border-box;
    padding: 2rem;
    margin: 0 -4rem;
    font-family: var(--font-family);
    font-size: 1.4rem;

    @media (--screen-xl-xxl) {
        width: 100%;
        max-width: 50rem;
        margin: 0;
        padding: 5rem;
    }
}

.newsletter__form__title {
    font-family: var(--font-family);
    font-size: 2rem;
    color: var(--color-purple);
    text-transform: uppercase;
    font-weight: 700;
}
