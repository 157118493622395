.container {
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0 var(--side-padding);
    box-sizing: border-box;

    &.-center {
        text-align: center;
    }

    .site-app & {
        padding: 0;
    }
}
