p {
    margin: 1rem 0 3.2rem;

    &.-orange {
        color: var(--color-orange);
    }

    &.empty {
        color: var(--color-white);

        &.-purple {
            color: var(--color-purple);
        }
    }
}
